import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Attributes {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.attributes = base
  }

  async getStoreAttributes (partyId, limit, offset) {
    await AuthToken.setAuth(this.attributes, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.attributes({
      url: `/party_attribute_value/${partyId}/${limit}/${offset}`,
      method: 'GET'
    })
  }
}

export default new Attributes()
